import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
import TheNois from '../images/theNOIS-scrn.png';
import CareRing from '../images/CareRing-scrn.png';
import CoconutsInc from '../images/CoconutsInc-scrn.png';
import OSRSComp from '../images/OSRS-comp-screenshot.png';
import RockClimbingBg from '../images/rock-climbing-bg.jpg';
import { HireMe, LinkButton } from '../components/Button.js';
import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Colors from '../Colors';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { darken } from 'polished';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${Colors.darkest};
  }
  ${t.LargeP} {
    color: ${Colors.darkest};
  }
`;

const Block = styled.div`
  &:nth-child(odd) {
    border: solid 1px ${darken(0.1, Colors.light)};
    background-color: ${Colors.light};
  }
`;

const BlockContent = styled(Content)`
  ${Mixins.block}
  padding: 100px 40px;
  ${media.tablet`
    flex-direction: column;
    align-items: baseline;
  `};
  ${media.phone`
    padding: 40px 10px;
  `};
  ${t.P} {
    margin-top: 10px;
    color: ${Colors.darkest};
    font-size: 20px;
  }
  ${t.H2} {
    margin-top: 0;
    color: ${Colors.darkest};
  }
  ${LinkButton} {
    font-size: 20px;
  }
  img {
    width: 100%;
    height: auto;
    border-style: solid;
    border-width: thin;
  }
`;

const DivWrapper = styled.div`
  padding: 80px 30px;
  ${media.tablet`padding: 50px 0;`}
  &:first-child {
    ${media.tablet`
      margin-bottom: 40px;
  `};
  }
`;

const ItemImage = styled.img`
  max-width: 85%;
  position: relative;
  ${media.tablet`max-width: none;`}
`;

const HomepageWrapper = styled.div`
  ${Mixins.wrapper}
  .who-desc {
    display: block;
    margin: 0 auto 0px auto;
    color: ${Colors.darkest};
    max-width: 75%;
    ${media.tablet`max-width: 90%;`}
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.P} {
    font-size: 18px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }
  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underlined;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`;

const WorkWithMe = styled.div`
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(57, 55, 55, 0.08);
  background-color: ${Colors.light};
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
    color: ${Colors.darkest};
  }
  ${t.H1} {
    color: ${Colors.darkest};
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`;

class Homepage extends React.Component {
  state = {
    openHireMePopup: false
  };

  handleRequestDemoClose = () => {
    this.setState({
      openHireMePopup: false
    });
  };

  openContactPopup = () => {
    this.setState({
      openHireMePopup: true
    });
  };

  render() {
    const { openHireMePopup } = this.state;
    const { data } = this.props;
    return (
      <HomepageWrapper>
        <Layout theme="white" bigFooter openContactPopup={this.openContactPopup}>
          <AboveFold style={{ backgroundImage: `url(${RockClimbingBg})` }}>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Christopher Pietka" className="avatar" />
            <t.H1 primary align="center">
              Christopher Pietka
            </t.H1>
            <t.LargeP align="center" max50>
              I'm a software engineer living in the Chicago suburbs, open to new opportunities.
            </t.LargeP>
            <t.LargeP max70>
              I specialize in full stack software development primarily in JavaScript/Typescript, Java, and Python.
              Utilizing tech such as React, Redux, Node, Express, PostgreSQL, and Neo4j.
            </t.LargeP>
            <HireMe large onClick={this.openContactPopup} book>
              Hire me
            </HireMe>
          </AboveFold>
          <Content>
            <t.H2 primary align="center" bold>
              About me
            </t.H2>
            <t.P align="center" max70 className="who-desc">
              I've recently completed my Bachelor's in Computer Science at Western Governor's University this spring
              2022. Learning new technologies and mastering the ones I know is my passion. My experiences have provided
              me the technical skills necessary to flourish in software development and make a positive impact on
              business outcomes.
            </t.P>
            <t.H2 primary align="center" bold className="portfolio">
              Projects
            </t.H2>
          </Content>
          <Block>
            <BlockContent>
              <DivWrapper>
                <ItemImage src={TheNois} alt="theNois" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>theNOIS</t.H2>
                <t.P>
                  A service which provides songwriters with the ability to collect their unpaid royalties accrued from
                  various streaming services.
                </t.P>
                <t.P>Built primarily using TypeScript, React, Redux, and Neo4j.</t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.thenois.org/"
                >
                  Check out the live site here!
                </LinkButton>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>OSRS-Competitions</t.H2>
                <t.P>A website that tracks player data from the online game OldSchool RuneScape.</t.P>
                <t.P>Provides real-time competition rankings. Work in progress, interactive features underway.</t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://osrs-competitions.netlify.app/"
                >
                  Check out the live site here!
                </LinkButton>
              </DivWrapper>
              <DivWrapper style={{ display: 'flex', justifyContent: 'center' }}>
                <ItemImage style={{ width: 'auto', height: '10em' }} src={OSRSComp} alt="OSRSComp" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper style={{ display: 'flex', justifyContent: 'center' }}>
                <ItemImage style={{ width: 'auto' }} src={CareRing} alt="CareRing" />
              </DivWrapper>
              <DivWrapper>
                <t.H2 bold>CareRing</t.H2>
                <t.P>A mobile app that connects small nonprofit organizations and volunteers locally.</t.P>
                <t.P>Founded on React Native / Expo, Redux, PostgreSQL, and Neo4j.</t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.github.com/volunteers-connect/care-ring"
                >
                  Check it out on GitHub!
                </LinkButton>
              </DivWrapper>
            </BlockContent>
          </Block>
          <Block>
            <BlockContent>
              <DivWrapper>
                <t.H2 bold>Coconuts, Inc</t.H2>
                <t.P>An E-commerce website selling fun tropical-themed products.</t.P>
                <t.P>Created with React, Redux, Express, PostgreSQL, Sequelize, Passport, and Stripe.</t.P>
                <LinkButton
                  primary
                  bold
                  className="link"
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/team-coconut-tiki/grace-shopper"
                >
                  Check it out on GitHub!
                </LinkButton>
              </DivWrapper>
              <DivWrapper>
                <ItemImage src={CoconutsInc} alt="Coconuts, Inc" />
              </DivWrapper>
            </BlockContent>
          </Block>
          <WorkWithMe>
            <t.H1 green>Let me know what you think</t.H1>
            <t.LargeP>If my projects or skillset sparked your interest please reach out to me!</t.LargeP>
            <HireMe onClick={this.openContactPopup} book>
              Contact me
            </HireMe>
          </WorkWithMe>
        </Layout>
        <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} />
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
  }
`;
